.b-product-badge {
    background-color: rgba($white, 0.85);
    font-size: 11px;
    font-weight: 500;

    letter-spacing: 0.1em;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    @if ($feature-redesign) {
        @include n10;
        text-transform: none;
    }
    
    &::before,
    &::after {
        content: "";
        display: block;
        height: 9px;

        @media (--xxl) {
            height: 12px;
        }
    }
}
