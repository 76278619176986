.b-variations {
    text-align: center;
    user-select: none;

    &__title {
        font-size: $font-size-secondary;
        margin-bottom: 15px;
        text-transform: lowercase;
        @if ($feature-redesign) {
            @include n10;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__list {
        @if ($feature-redesign) {
            font-family: $font-family-sans;
        }
        font-size: 0;
        margin: -2.5px -5px;
    }

    &__button {
        background: none;
        border: 1px solid transparent;
        font-size: $font-size-primary;
        line-height: 1;
        margin: 2.5px 5px;
        min-width: 26px;
        padding: 6px 4px;
        text-align: center;

        @if ($feature-redesign) {
            @include n10;
        }

        &:last-child {
            margin-right: 0px;
        }

        @media (--lg-up) {
            font-size: $font-size-secondary;
            @if ($feature-redesign) {
                @include n10;
            }
        }

        @media (max-width: 1294px) and (min-width: 1199px) {
            margin: 2.5px 3px;
        }

        @media (max-width: 354px) and (min-width: 320px) {
            margin: 2.5px 4px;
        }

        .non-touch &:hover {
            border-color: $gray-100;
        }

        &.selected {
            border-color: $black;
        }

        &.unavailable {
            border-color: transparent;
            color: $gray-300;
            cursor: default;
            text-decoration: line-through;
        }
    }
}
